<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		animationClass: "show-up" | "blink";
		transition: string;
		transitionDelay: string;
		threshold: number;
		rootMargin: string;
	}>(),
	{
		animationClass: "show-up",
		transition: "0.5s",
		transitionDelay: "0.5s",
		threshold: 0.5,
		rootMargin: "0px"
	}
);

const element = ref<HTMLElement | null>(null);

const transition = computed(() => props.transition);
const transitionDelay = computed(() => props.transitionDelay);

const handleScroll = () => {
	const observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("active");
					observer.unobserve(entry.target);
				}
			});
		},
		{
			threshold: props.threshold,
			rootMargin: props.rootMargin
		}
	);

	if (element.value) {
		observer.observe(element.value);
	}
};

onMounted(() => {
	handleScroll();
});
</script>

<template>
	<div ref="element" :class="animationClass">
		<slot />
	</div>
</template>

<style lang="scss" scoped>
@keyframes blink {
	0% {
		left: 0;
	}
	99% {
		left: 200px;
	}
	100% {
		content: none !important;
	}
}

.show-up {
	opacity: 0;
	transition: v-bind(transition);
	transition-delay: v-bind(transitionDelay);

	&.active {
		opacity: 1;
	}
}

.blink {
	:deep(> *) {
		&::after {
			content: "" !important;
			position: absolute;
			z-index: 5;
			left: -80px;
			top: -10px;
			width: 60px;
			height: calc(100% + 60px);
			background-image: linear-gradient(
				90deg,
				rgba(229, 172, 142, 0) 0%,
				rgba(255, 255, 255, 1) 50%,
				rgba(229, 172, 142, 0) 100%
			);
			transform: rotate(38deg);
		}
	}

	&.active {
		:deep(> *) {
			&::after {
				content: "" !important;
				animation: blink v-bind(transition) linear v-bind(transitionDelay) forwards;
			}
		}
	}
}
</style>
